<template>

    <div class="h-full relative">

        <loader :loading="loading" />

        <div v-if="view != 'mobile'" class="scroll-gradient"></div>

        <div v-if="posts && view != 'mobile'" class="h-full overflow-y-auto px-2">
            <post v-for="(el,index) in posts" :key="index" :data="el"/>
            <div class="h-10 flex flex-col justify-center items-center cursor-pointer mb-4" @click="loadMore">
                <span class="text-lg text-aux">Cargar más</span>
            </div>
        </div>

        <div v-if="view == 'mobile'" class="h-full">

            <swiper :options="swiperOptions" class="h-full w-full">

                <swiper-slide v-for="(el, index) in posts" :key="index" class="h-full w-full">
                    <post :data="el" :mobile="true"/>
                </swiper-slide>

                <swiper-slide class="h-full w-full">

                    <div class="h-full w-full flex flex-col justify-center items-center bg-module-top_70 rounded-lg" @click="loadMore">
                        <span class="text-xs text-aux">Cargar más</span>
                    </div>

                </swiper-slide>

            </swiper>

        </div>

    </div>

</template>

<script>
    import { state } from '@/store';
    import post from './post.vue';

    export default {
        components:{
            post
        },
        props: ['view'],
        data() {
            return {
                loading: false,
                posts: [],
                page:1,
                busy:false,
                swiperOptions: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                    // grabCursor: true,
                }
            }
        },
        methods: {
            load() {
                this.loading = true
                this.axios.get(process.env.VUE_APP_SOCIAL_URL + 'social/posts?page='+this.page)
                    .then(response => {
                        this.loading = false

                        if(this.page == 1){
                            this.posts = response.data.data;
                        } else {
                            response.data.data.forEach(element => {
                                this.posts.push(element)
                            });
                        }

                    })
            },
            loadMore(){
                this.page++;
                this.load();
            },
            colorBySocialNetwork(social) { return this.$colorSocialNetwork(social) }
        },
        computed: {
            params() { return state.globalParams },
            imageRoute() { return this.$imageRoute }
        },
        watch: {
            params() { this.load() }
        },
        mounted() { 
            this.load() 

            setInterval(() => {
                this.load();
            }, state.refreshPersonalDashboard);
        
        }
    }
</script>