<template>

    <div class="w-full shadow-md mb-3 rounded-lg bg-module-top_70 overflow-hidden flex flex-col" :class="{'h-full': mobile, 'h-48': !mobile}">

        <div class="flex-1 min-h-0 flex flex-col px-2" :class="{'rounded-lg': mobile}">

            <div class="flex" :class="{'h-12 mb-2': !mobile, 'h-6': mobile}">

                <div class="h-full w-full flex flex-col justify-center items-start" :class="{'my-1' : mobile}">

                    <div class="h-auto w-full flex flex-row justify-start items-center">

                        <span class="font-semibold capitalize truncate-1" :class="{'text-xxs': mobile, 'text-xs': !mobile}">{{data.name}}</span>
                        <span class="ml-auto" :class="{'text-3xs' : mobile, 'text-xxs': !mobile}">{{data.date | moment('DD MMM, HH:mm')}}</span>

                    </div>

                    <span :class="{'text-3xs': mobile, 'text-xxs': !mobile}" v-if="data.channels && data.channels.length > 0"> in #{{data.channels[0].name}} <small v-if="data.channels.length > 1">- and {{data.channels.length-1}} other</small></span>

                </div>

            </div>

            <div class="h-auto flex w-full mb-2" :class="{'mt-auto' : mobile}">

                <div v-if="data.media_url" :class="{'h-20 w-20': !mobile, 'h-12 w-12': mobile}" class="rounded-lg shadow-md mr-2 flex-none" :style="'background-image: url(' + data.media_url + '); background-repeat: no-repeat; background-size: cover; background-position: center;'"></div>

                <span class="text-left" :class=" {
                            'text-sm': !mobile,
                            'truncate-6': !mobile,
                             'text-xxs': mobile,
                             'truncate-5': mobile }">{{data.text}}</span>

            </div>

        </div>

        <div class="h-8 bg-box flex flex-row justify-start items-center px-2">

            <i class="mdi mdi-thumb-up-outline text-xxs mr-1"></i>
            <span class="text-xxs">{{data.likes}}</span>

            <i class="mdi mdi-comment-outline text-xxs mr-1 ml-3"></i>
            <span class="text-xxs">{{data.comments}}</span>

            <div class="h-full w-12 ml-auto flex flex-col justify-center items-center cursor-pointer" @click="goLink(data.link)">
                <i class="mdi mdi-open-in-new text-gray-600" :class="{'text-xs': mobile}"></i>
            </div>

        </div>

    </div>

</template>

<script>
export default {
    props:['data', 'mobile'],
    methods:{
        goLink(link){
            window.open(link, '_blank').focus();
        }
    }
}
</script>

<style>

</style>